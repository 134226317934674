import { createSlice } from "@reduxjs/toolkit";
const feedbackStateSlice = createSlice({
    name: "feedbackStateStore",
    initialState: {
        feedbackState: undefined,
        hasHeaderLoaded: false,
        activityId: undefined,
        hideSendBox: undefined,
        hasThemeLoaded: false,
        policyAgreed: false,
        feedbackCompleted: false,
        feedbackStateReady: false
    },
    reducers: {
        setFeedbackState(state, action) {
            state.feedbackState = action.payload;
        },
        updateFeedbackArgs(state, action) {
            if (state.feedbackState) {
                state.feedbackState.args = action.payload;
            }
        },
        setHeaderFlag(state, action) {
            state.hasHeaderLoaded = action.payload;
        },
        setHasThemeLoaded(state, action) {
            state.hasThemeLoaded = action.payload
        },
        setActivityId(state, action) {
            state.activityId = action.payload;
        },
        setHideSendBox(state, action) {
            state.hideSendBox = action.payload;
        },
        setPolicyAgreed(state, action) {
            state.policyAgreed = action.payload
        },
        setFeedbackCompleted(state, action) {
            state.feedbackCompleted = action.payload;
        },
        setFeedbackStateReady(state, action) {
            state.feedbackStateReady = action.payload;
        }
    }
});
export const feedbackStateSliceActions = feedbackStateSlice.actions;
export default feedbackStateSlice;